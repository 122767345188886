import type { ContactMethod } from '@integration-layer/ampliance/schemas/contact-method-schema.localized'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

export const useContactUsSideSlide = () => {
  const router = useRouter()
  const localePath = useLocalePath()
  const { checkoutUrl } = useRouteHelper()
  const { openLink, isChatMethod } = useContacts()
  const { openInsideChat } = useInsideChat()
  const { dispatchActionContactUsEvent, dispatchActionContactCheckoutEvent } =
    useGAActionEvents()
  const { closeDialog } = useDialog()

  const { data } = useAsyncData(
    'CONTACT_US_CONTENT',
    async () => {
      const query = {
        locale: localePath(''),
        vse: useRoute().query.vse,
        contentId: useRoute().query.contentId,
      }

      const [contactUsInfos, contacts] = await Promise.allSettled([
        $fetch('/api/cms/getInfosWithTitle', {
          query: {
            ...query,
            deliveryKey: 'contact-us-info',
          },
        }),
        $fetch('/api/cms/getAllContacts', {
          query: {
            ...query,
            deliveryKey: 'all-contact-methods',
          },
        }),
      ])

      return {
        description: isPromiseFullfilled(contactUsInfos)?.content?.label ?? '',
        contacts: isPromiseFullfilled(contacts)?.content.methods ?? [],
      }
    },
    {
      server: false,
      default: () => ({ description: '', contacts: [] }),
      getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
    }
  )

  const contactUsDescription = computed(() => data.value.description)

  const contactMethods = computed(
    () =>
      data.value.contacts.filter(
        contact =>
          !contact.hideInAllCountries?.value?.sidebar &&
          !contact.hideInCountry?.sidebar
      ) ?? []
  )

  const isCheckoutPage = computed(() =>
    router.currentRoute.value.path.includes(checkoutUrl)
  )

  const dispatchClickEvent = (title: string) => {
    if (isCheckoutPage.value) {
      dispatchActionContactCheckoutEvent('popup', title)
      return
    }
    dispatchActionContactUsEvent()
  }

  const handleContactClick = (name: string, cta: ContactMethod['cta']) => {
    dispatchClickEvent(cta?.externalLink?.link ?? name)

    if (isChatMethod(name)) {
      closeDialog(SIDEBAR_IDS.cartContactUsSideSlide)
      openInsideChat()
      return
    }

    const _link = cta?.internalCta?.link
    if (!_link) return
    openLink(_link, cta?.internalCta?.type)
  }

  return {
    contactUsDescription,
    contactMethods,
    handleContactClick,
  }
}
